import React, { createContext, useState, useContext } from 'react'
import { CommentsContextState } from 'types/context'

const IFirebaseCommentsDefaultValue = {
  isLogin: false,
  setLogin: (state: boolean) => {},
  user: [],
  setUser: (state: any) => {},
  comments: [],
  setComments: (state: any) => {},
}

type Props = { children: React.ReactNode }

export const FirebaseCommentsContext = createContext<CommentsContextState>(
  IFirebaseCommentsDefaultValue
)

export const FirebaseCommentsProvider: React.FC<Props> = ({ children }) => {
  const [isLogin, setLogin] = useState<boolean>(false)
  const [user, setUser] = useState<any>([])
  const [users, setUsers] = useState<any>([])
  const [comments, setComments] = useState<any>([])

  const value = {
    isLogin,
    setLogin,
    user,
    setUser,
    comments,
    setComments,
  }

  return (
    <FirebaseCommentsContext.Provider value={value}>
      {children}
    </FirebaseCommentsContext.Provider>
  )
}

export const useFirebaseCommentsContext = () =>
  useContext(FirebaseCommentsContext)
