import React, { createContext, useContext, useState } from 'react'
import { MenuContextState } from 'types/context'

const IMenuDefaultState = {
  clickMenu: false,
  setClickMenu: (state: boolean) => {},
  showNav: false,
  setShowNav: (state: boolean) => {},
  showMenu: false,
  setShowMenu: (state: boolean) => {},
}

type Props = { children: React.ReactNode }

export const MenuContext = createContext<MenuContextState>(IMenuDefaultState)

export const MenuProvider: React.FC<Props> = ({ children }) => {
  const [clickMenu, setClickMenu] = useState<boolean>(false)
  const [showNav, setShowNav] = useState<boolean>(false)
  const [showMenu, setShowMenu] = useState<boolean>(false)

  const value = {
    clickMenu,
    setClickMenu,
    showNav,
    setShowNav,
    showMenu,
    setShowMenu,
  }

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
}

export const useMenuContext = () => useContext(MenuContext)
