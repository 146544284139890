import React from 'react'

import './src/styles/_global.css'

import { ModalProvider } from './src/hooks/modal-context'
import { MenuProvider } from './src/hooks/menu-context'
import { FirebaseCommentsProvider } from './src/hooks/comment-firebase-context'

export const wrapRootElement = ({ element }) => {
  return (
    <ModalProvider>
      <FirebaseCommentsProvider>
        <MenuProvider>{element}</MenuProvider>
      </FirebaseCommentsProvider>
    </ModalProvider>
  )
}
